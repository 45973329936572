import { Injectable, OnDestroy } from '@angular/core';
import userProjectFind, { userProjectTaskPerm } from '@app/_helpers/userProjectFind';
import { createRxValue, distinctUntilChangedJson, fromRxValue } from '@app/_helpers/utils';
import { TIME_RANGE_DEFAULT_RANGES, TRange } from '@app/shared/time-range-picker/time-range-constants';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { endOfDay, isValid, isWithinInterval } from 'date-fns/esm';
import { flow } from 'lodash-es';
import { BehaviorSubject, Subject, Subscription, combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import {
  Logger,
  NotifyService,
  Project,
  ProjectsQuery,
  Task,
  TasksQuery,
  TasksService,
  Time,
  TimesService,
  UserSettingsQuery,
} from 'timeghost-api';

const log = new Logger('ProjectPageService');
@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class ProjectPageService implements OnDestroy {
  readonly isViewContext = createRxValue(false);
  private onDestroy = new Subject<void>();
  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
  private _projectId = createRxValue<string>(null);
  get projectId() {
    return this._projectId.value;
  }
  readonly projectId$ = this._projectId
    .asObservable()
    .pipe(takeUntil(this.onDestroy), startWith(<string>null), distinctUntilChanged());
  readonly project$ = combineLatest([this.projectsQuery.selectAll(), this.projectId$])
    .pipe(
      filter(([projects, projectId]) => projects?.length > 0 && !!projectId),
      map(([projects, id]) => projects.find((x) => x.id === id)),
    )
    .pipe(takeUntil(this.onDestroy), distinctUntilChanged());
  get project() {
    return this.projectsQuery.getEntity(this._projectId.value);
  }
  get isTemplate() {
    return this.project?.projectType === 'template';
  }
  readonly range = createRxValue<TRange>(TIME_RANGE_DEFAULT_RANGES.week);
  readonly range$ = this.range.asObservable().pipe(
    takeUntil(this.onDestroy),
    filter((x) => x?.from && x?.to && isValid(x.from) && isValid(x.to)),
  );
  readonly projectTimes$Loading = createRxValue(false);
  readonly projectTimes$ = fromRxValue(
    combineLatest([this.range$, this.project$]).pipe(
      filter(([x, project]) => !!project?.id),
      distinctUntilChangedJson(([x, project]) => `${project.id}${x.from?.toISOString()}${x.to?.toISOString()}`),
      tap(() => this.projectTimes$Loading.update(true)),
      debounceTime(100),
      switchMap(([range, project]) => {
        const start = range.from;
        return this.timesService.getByProjectBetweenRange(project, start, range.to ?? endOfDay(start.getTime()));
      }),
      tap((x) => {
        this.projectTimes$Loading.update(false);
      }),
    ),
    [],
  );
  private timeIsBetweenRange(time: Time, range?: TRange) {
    if (!range) range = this.range.value;
    return flow((dt: Date) => isWithinInterval(dt, { start: range.from, end: range.to }))(new Date(time.start));
  }
  readonly projectTimesRanged$ = this.projectTimes$.asObservable().pipe(tap((x) => log.debug('dx', x)));
  get projectTimes() {
    return this.projectTimes$.value;
  }
  set projectTimes(val: Time[]) {
    this.projectTimes$.value = val;
  }
  readonly _projectTasks = createRxValue<Task[]>(null);
  readonly projectTasks$ = combineLatest([
    this._projectTasks.asObservable().pipe(takeUntil(this.onDestroy), distinctUntilChanged()),
    this.project$,
    this.userSettingsQuery.select(),
  ]).pipe(
    map(([tasks, project, userSettings]) => {
      return tasks;
    }),
  );
  get projectTasks() {
    return this._projectTasks.value;
  }
  set projectTasks(val: Task[]) {
    this._projectTasks.next(val);
  }
  private _showCompletedTasks = new BehaviorSubject<boolean>(false);
  readonly showCompletedTasks$ = this._showCompletedTasks
    .asObservable()
    .pipe(startWith(this._showCompletedTasks.getValue()), distinctUntilChanged());
  get showCompletedTasks() {
    return this._showCompletedTasks.getValue();
  }
  set showCompletedTasks(val: boolean) {
    this._showCompletedTasks.next(val);
  }
  readonly project$authed = combineLatest([this.userSettingsQuery.select(), this.project$]).pipe(
    filter(([userSettings, project]) => !!project),
    map(([userSettings, project]) => {
      if (project.private) return userProjectFind(userSettings, project, { workspaceAdmin: true });
      return true;
    }),
  );
  readonly project$admin = combineLatest([this.userSettingsQuery.select(), this.project$]).pipe(
    filter(([userSettings, project]) => !!project),
    map(([userSettings, project]) => {
      return !!project.currentUserCanEdit;
    }),
  );
  readonly project$adminOrAuthor = combineLatest([this.userSettingsQuery.select(), this.project$]).pipe(
    filter(([userSettings, project]) => !!project),
    map(([userSettings, project]) => {
      return userProjectFind(userSettings, project, {
        projectManager: true,
        workspaceAdmin: true,
      });
      // return permissionSettings.onlyAdminCanCreateProjects ? !!users.find(x => x.admin && x.id === userSettings.id) : true;
    }),
  );
  readonly project$canEdit = combineLatest([this.userSettingsQuery.select(), this.project$]).pipe(
    filter(([userSettings, project]) => !!project),
    map(([userSettings, project]) => {
      return !!project.currentUserCanEdit && !project.completed;
    }),
  );
  readonly project$tasksEdit = combineLatest([this.userSettingsQuery.select(), this.project$]).pipe(
    filter(([userSettings, project]) => !!project),
    map(([userSettings, project]) => {
      return userProjectTaskPerm(userSettings, project);
    }),
  );
  readonly project$permission = combineLatest([this.userSettingsQuery.select(), this.project$]).pipe(
    filter(([userSettings, project]) => !!project),
    map(([userSettings, project]) => {
      return {
        canView: !project.private || userProjectFind(userSettings, project, { workspaceAdmin: true }),
        canEdit: userProjectFind(userSettings, project, { projectManager: true, workspaceAdmin: true }),
        canDelete: userProjectFind(userSettings, project, { projectManager: true, workspaceAdmin: true }),
      };
    }),
  );
  readonly selectedTask = createRxValue<Task>();
  constructor(
    private taskService: TasksService,
    private userSettingsQuery: UserSettingsQuery,
    private projectsQuery: ProjectsQuery,
    private tasksQuery: TasksQuery,
    private timesService: TimesService,
    private notifyService: NotifyService,
  ) {}
  setId(id: string) {
    this._projectId.next(id);
  }
  private _subscriptions: Subscription[] = [];
  setProject(project: Project) {
    this._subscriptions.every((x) => {
      x.unsubscribe();
      return x.closed;
    });
    this._subscriptions = [];
    if (!project) {
      this._projectId.next(null);
      return;
    }
    this._projectId.next(project.id);
    this._subscriptions.push(
      this.tasksQuery
        .selectAll({
          filterBy: (x) => x.project.id === project.id,
        })
        .subscribe((x) => (this.projectTasks = x)),
    );
    this._subscriptions.push(
      this.notifyService.onMessage
        .asObservable()
        .pipe(
          untilDestroyed(this),
          filter(
            (x) => x.payload?.cosmosEntityName === 'times' && this.timeIsBetweenRange(x.payload, this.range.value),
          ),
        )
        .subscribe(({ type, payload }: { type: number; payload: Time }) => {
          const idx = this.projectTimes.findIndex((t) => t.id === payload.id);
          log.debug('[notify] :: hit');
          if (type === 1 && idx === -1 && payload.project.id !== this.project.id) return;
          if (type === 1 && idx !== -1) type = 2;
          if (type === 2 && payload.project.id !== this.project.id) type = 3;
          if (type === 2 && idx === -1) type = 1;
          if (type === 1)
            this.projectTimes$.update((s) => {
              s.push(payload);
              return s;
            }, true);
          else if (type === 2 && idx !== -1) {
            this.projectTimes$.update((s) => {
              s.splice(idx, 1, payload);
              return s;
            }, true);
          } else if (type === 3 && idx !== -1)
            this.projectTimes$.update((s) => s.filter((d) => d.id !== payload.id), true);
          log.debug('[notify] :: times', type, payload);
        }),
      this.notifyService.onMessage
        .asObservable()
        .pipe(
          untilDestroyed(this),
          filter((x) => x.payload?.cosmosEntityName === 'tasks'),
        )
        .subscribe(({ type, payload }: { type: number; payload: Task }) => {
          const idx = this.projectTasks.findIndex((t) => t.id === payload.id);
          log.debug('[notify] :: hit');
          if (type === 1 && idx === -1 && payload.project.id !== this.project.id) return;
          if (type === 1 && idx !== -1) type = 2;
          if (type === 2 && payload.project.id !== this.project.id && idx !== -1) type = 3;
          if (type === 2 && idx === -1) type = 1;
          if (type === 1 && payload.project?.id === this.project?.id)
            this._projectTasks.update((s) => {
              s.push(payload);
              return s;
            }, true);
          else if (type === 2 && idx !== -1) {
            this._projectTasks.update((s) => {
              s.splice(idx, 1, payload);
            }, true);
          } else if (type === 3 && idx !== -1)
            this._projectTasks.update((s) => s.filter((d) => d.id !== payload.id), true);
          log.debug('[notify] :: tasks', type, payload);
        }),
    );
  }
}
